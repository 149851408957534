import React, { useState } from "react";
import { useIsMobile } from "../../hooks";
import { motion, AnimatePresence } from "framer-motion";
import { SUPPORTED_REGIONS } from "../../../shared/enums";

/**
 * @returns {JSX.Element}
 * @constructor
 */

const timelineData = [
  {
    day: (
      <>
        <strong className="number">
          1<span>st</span>
        </strong>
        <span className="day-text">day</span>
      </>
    ),
    title: (
      <>
        The beginning of a <br /> beautiful relationship.
      </>
    ),
    icon: <>👋</>,
    description: ({ region }) => (
      <>
        As soon as you bring Mila home, you’re part of the family. We’ll be on
        standby to make sure setup goes smoothly and you two get well
        acquainted.
      </>
    ),
  },
  {
    day: (
      <>
        <strong className="number">30</strong>
        <span className="day-text">days</span>
      </>
    ),
    title: <>Spend a month together before you commit.</>,
    icon: <>💍</>,
    description: ({ region }) => (
      <>
        Not all relationships are meant to be. So if you don’t love Mila, you
        can return her within 30 days for a full refund, minus shipping and
        handling. (Just let her down gently.)
      </>
    ),
  },
  {
    day: (
      <>
        <strong className="number">2</strong>
        <span className="day-text">years</span>
      </>
    ),
    title: <>Your Mila is fully covered if things get rocky.</>,
    icon: "🤝",
    description: ({ region }) => (
      <>
        As time goes on, you may have a few ups and downs—it’s natural. If you
        find yourself having problems with Mila, no worries. We’ll replace her
        right away, without any “he said, she said” drama.
      </>
    ),
  },
  {
    day: (
      <>
        <strong className="number">3</strong>
        <span className="day-text">years</span>
      </>
    ),
    title: <>Extended coverage if you’re a filter subscriber.</>,
    icon: <>🤗</>,
    description: ({ region }) => (
      <>
        When you get fresh filters delivered with our Auto-Refill program, you
        get a whole extra year of warranty coverage at no cost. That’s true
        partnership. ❤️
      </>
    ),
  },
  {
    day: (
      <>
        <strong className="number">5</strong>
        <span className="day-text">years</span>
      </>
    ),
    title: <>Extra-extra coverage for filter subscribers.</>,
    icon: <>❤️</>,
    description: ({ region }) => (
      <>
        With Auto-Refill, if anything goes wrong during years 4 and 5 of your
        Mila ownership, you’ll get a brand new replacement for just{" "}
        {region === SUPPORTED_REGIONS.CA ? "$149" : "$99"}. We’re here to keep
        the love alive.
      </>
    ),
  },
  {
    day: (
      <>
        <strong className="number">4ever </strong>
        <span className="day-text">& ever</span>
      </>
    ),
    title: <>Diehard support from the Mila Cares Squad.</>,
    icon: <>🤝</>,
    description: ({ region }) => (
      <>
        We’re not afraid to commit for the long haul. As long as you own your
        Mila, you can contact us about any issue and we’ll be happy to dive in
        with you and solve it.
      </>
    ),
  },
];
const TimelineItem = ({
  isMobile,
  item,
  isActive,
  onToggle,
  region = SUPPORTED_REGIONS.US,
  index,
}) => {
  return (
    <motion.div
      className={`timeline-item ${isActive ? " active" : ""}`}
      initial={{ opacity: 0 }}
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay: `${0.01 * index}` }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
    >
      <div className="timeline-card">
        <div className="timeline-card__header">
          <div className="timeline-item__day">{item.day}</div>
        </div>

        <div className="timeline-card__footer">
          <h3 className="timeline-item__title">{item.title}</h3>
          <span className="timeline-item__icon">{item.icon}</span>
        </div>
        {isMobile && (
          <>
            <AnimatePresence>
              {isActive && (
                <motion.div
                  className="timeline-item__description"
                  initial={{ height: 0 }}
                  animate={{ height: "auto" }}
                  exit={{ height: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <p>{item.description({ region })}</p>
                </motion.div>
              )}
            </AnimatePresence>

            <button
              type="button"
              className="timeline-item__toggle"
              onClick={onToggle}
            >
              <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
                <path d="M1 1L6.5 6.5L12 1" stroke="black" strokeWidth="2" />
              </svg>
              <span className="screen-reader-text">arrow down</span>
            </button>
          </>
        )}
      </div>
      {!isMobile && (
        <div className="timeline-item__description">
          <p>{item.description({ region })}</p>
        </div>
      )}
    </motion.div>
  );
};

const MilaTimeline = ({ localization }) => {
  const { region } = localization;
  const regional = SUPPORTED_REGIONS[region] || SUPPORTED_REGIONS.US;
  const isMobile = useIsMobile("769");
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="mila-timeline">
      <div className="mila-timeline__card">
        <div className="container">
          <div className="mila-timeline__header">
            <motion.h2
              className="mila-timeline__title"
              initial={{ opacity: 0, y: 20 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 20 },
              }}
            >
              Mila is your ride-or-die home appliance.
            </motion.h2>
            <div className="mila-timeline__description">
              <motion.p
                className="subtitle"
                initial={{ opacity: 0, y: 20 }}
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.01 }}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  hidden: { opacity: 0, y: 20 },
                }}
              >
                It’s not marriage, <br />
                but it’s close.
              </motion.p>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.02 }}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  hidden: { opacity: 0, y: 20 },
                }}
              >
                Our customers buy Mila for the health of their loved ones, and
                we take that responsibility seriously. We’re here to support you
                for a long, long time.
              </motion.p>
            </div>
          </div>
          <div className="mila-timeline__body">
            {isMobile ? (
              <div className="mila-timeline__list">
                {renderTimelineItems(
                  isMobile,
                  regional,
                  activeIndex,
                  handleToggle
                )}
              </div>
            ) : (
              <div className="mila-timeline__grid">
                {renderTimelineItems(isMobile, regional, activeIndex, handleToggle)}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const renderTimelineItems = (isMobile, region, activeIndex, handleToggle) =>
  timelineData.map((item, index) => (
    <TimelineItem
      key={index}
      item={item}
      region={region}
      isMobile={isMobile}
      isActive={activeIndex === index}
      onToggle={() => handleToggle(index)}
      index={index + 1}
    />
  ));

export default MilaTimeline;
